import React from "react";
import { Link, NavLink } from "react-router-dom";
import Sidebar from "./Pages/SidebarModal";
import { useAuth } from "./ContextAndHooks/AuthContext";
import InstallButton from "./InstallButton";
const HeaderTop = () => {
  const { isLogin } = useAuth();

  return (
    <div className="header-top">
      <div className="header-left">
        {/* <InstallButton/> */}
        {/* <Link to="/" >
          <img src="images/logo.png" alt="Logo" className="logo1" />
          </Link> */}
        <a className="register-btn rounded-pill d-flex align-items-center me-2 reg_btn downloadBtnName" href="https://aviatormaster.in/aviator_master.apk" download><span className="me-2">Download</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-android" viewBox="0 0 16 16">
            <path d="M2.76 3.061a.5.5 0 0 1 .679.2l1.283 2.352A8.9 8.9 0 0 1 8 5a8.9 8.9 0 0 1 3.278.613l1.283-2.352a.5.5 0 1 1 .878.478l-1.252 2.295C14.475 7.266 16 9.477 16 12H0c0-2.523 1.525-4.734 3.813-5.966L2.56 3.74a.5.5 0 0 1 .2-.678ZM5 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2m6 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
          </svg>
        </a>
      
      </div>
      {!isLogin && (
        <div className="header-right d-flex align-items-center">
          <NavLink
            to="/auth/register"
            className="register-btn rounded-pill d-flex align-items-center me-2 reg_btn"
          >
            Register
          </NavLink>
          <NavLink
            className="login-btn rounded-pill d-flex align-items-center me-2"
            id="login"
            to="/auth/Login"
          >
            Login
          </NavLink>
        </div>
      )}
      {isLogin && (
        <div className="header-right d-flex align-items-center">
          <Sidebar />
        </div>
      )}
    </div>
  );
};

export default HeaderTop;
